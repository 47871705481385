import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Pagos from "../components/finanzas/Pagos";
import Montos from "../components/finanzas/Montos";
import { PagosContext } from "../context/PagosContext";
import Preciario from "../components/finanzas/Preciario";
import { MontosContext } from "../context/MontosContext";
import { PreciarioContext } from "../context/PreciarioContext";
import { ContratosContext } from "../context/ContratosContext";
import ContratoForm from "../components/finanzas/ContratoForm";
import { ModalContext } from "../context/ModalContext";
import { ProyectosContext } from "../context/ProyectosContext";
import { isCoordinador } from "../utils/permisos";
import { DestajosContext } from "../context/DestajosContext";
import { CapacitorContext } from "../context/CapacitorContext";
import useProyecto from "../hooks/useProyecto";

const SingleContrato = () => {
  const { idContrato } = useParams();
  const { proyecto, getSingleProyecto } = useContext(ProyectosContext);
  const { contrato, selectContrato } = useContext(ContratosContext);
  const { montos, getMontosContrato } = useContext(MontosContext);
  const { modalComponent } = useContext(ModalContext);
  const { getDestajos } = useContext(DestajosContext);
  const { editMode } = useContext(PreciarioContext);
  const { platform } = useContext(CapacitorContext);
  const { pagos } = useContext(PagosContext);
  const { idProyecto } = useProyecto();

  useEffect(() => {
    getSingleProyecto(idProyecto);
    getDestajos(idProyecto);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProyecto]);

  useEffect(() => {
    getMontosContrato(idProyecto, idContrato);
    selectContrato(idProyecto, idContrato);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContrato]);

  const handleEdit = () => {
    modalComponent("Editar Contrato", <ContratoForm />);
  };

  const renderTitle = () => {
    if (contrato && contrato !== null && platform === "web") {
      return (
        <div className="row align-items-center my-2">
          <div className="col-10">
            <h2 className="mb-0">Contrato #{contrato.folio}</h2>
          </div>
          <div className="col-2 text-end">
            {isCoordinador(proyecto) && (
              <button className="btn btn-outline-primary" onClick={handleEdit}>
                <i className="fa fa-edit"></i>
              </button>
            )}
          </div>
        </div>
      );
    }
  };

  const renderMontos = () => {
    if (montos !== null && Array.isArray(pagos)) {
      return (
        <Montos
          autorizado={montos.autorizado}
          estimacion={montos.estimacion}
          ejecutado={montos.ejecutado}
          extra={montos.extra}
          pagos={pagos}
        />
      );
    }
  };

  const renderView = () => {
    if (proyecto !== null) {
      return <Preciario idContrato={idContrato} idProyecto={idProyecto} />;
    }
  };

  return (
    <div className="container-fluid overflow-y-auto">
      {renderTitle()}
      {renderMontos()}
      {renderView()}
      {proyecto !== null && (
        <Pagos
          idProyecto={idProyecto}
          idContrato={idContrato}
          editMode={editMode}
        />
      )}
    </div>
  );
};

export default SingleContrato;
