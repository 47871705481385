import { Capacitor } from "@capacitor/core";

export const BASE_URL =
  (process.env.NODE_ENV === "development" && Capacitor.getPlatform() === "web"
    ? "http://localhost:4001"
    : process.env.REACT_APP_BASE_URL) + "/api";
//Obras
export const OBRAS_RECEIVED = "OBRAS_RECEIVED";
export const CREATE_NUEVA_OBRA = "CREATE_NUEVA_OBRA";
export const SET_NOMBRE_OBRA = "SET_NOMBRE_OBRA";
export const SET_DIRECCION_OBRA = "SET_DIRECCION_OBRA";
export const SET_IMAGEN_OBRA = "SET_IMAGEN_OBRA";
export const DELETE_OBRA = "DELETE_OBRA";
export const VIEW_OPTIONS = "VIEW_OPTIONS";
export const HIDE_OPTIONS = "HIDE_OPTIONS";
export const EDIT_OBRA = "EDIT_OBRA";
//Obra
export const OBRA_RECEIVED = "OBRA_RECEIVED";
export const PARTIDAS_RECEIVED = "PARTIDAS_RECEIVED";
export const DESTAJOS_RECEIVED = "DESTAJOS_RECEIVED";
export const SUBDESTAJOS_RECEIVED = "SUBDESTAJOS_RECEIVED";
export const CLEAR_OBRA = "CLEAR_OBRA";
export const OBRA_CREADA = "OBRA_CREADA";
export const REORDENAR_DESTAJOS = "REORDENAR_DESTAJOS";
export const REORDENAR_SUBDESTAJOS = "REORDENAR_SUBDESTAJOS";
export const SET_PROPERTY_OBRA = "SET_PROPERTY_OBRA";
//Plantillas
export const SET_NOMBRE_PLANTILLA = "SET_NOMBRE_PLANTILLA";
export const PLANTILLAS_RECEIVED = "PLANTILLAS_RECEIVED";
export const SET_PLANTILLA = "SET_PLANTILLA";
//ProgresoPartidaChart
export const RETRASOS_RECEIVED = "RETRASOS_RECEIVED";
//PartidaForm
export const SET_PROPERTY_PARTIDA = "SET_PROPERTY_PARTIDA";
export const CREATE_NUEVA_PARTIDA = "CREATE_NUEVA_PARTIDA";
export const DELETE_PARTIDA = "DELETE_PARTIDA";
//DestajoForm
export const SET_PROPERTY_DESTAJO = "SET_PROPERTY_DESTAJO";
export const CREATE_NUEVO_DESTAJO = "CREATE_NUEVO_DESTAJO";
export const DELETE_DESTAJO = "DELETE_DESTAJO";
//SubdestajoForm
export const SET_PROPERTY_SUBDESTAJO = "SET_PROPERTY_SUBDESTAJO";
export const CREATE_NUEVO_SUBDESTAJO = "CREATE_NUEVO_SUBDESTAJO";
export const DELETE_SUBDESTAJO = "DELETE_SUBDESTAJO";
//editMode
export const EDIT_MODE_ON = "EDIT_MODE_ON";
export const EDIT_MODE_OFF = "EDIT_MODE_OFF";
export const SET_EDIT_MODE = "SET_EDIT_MODE";
export const CLEAR_EDIT_MODE = "CLEAR_EDIT_MODE";
export const SET_LEVEL = "SET_LEVEL";
//progresoObra
export const RESET_PROGRESO_OBRA = "RESET_PROGRESO_OBRA";
export const PROGRAMADO_DIA_RECEIVED = "PROGRAMADO_DIA_RECEIVED";
export const REAL_DIA_RECEIVED = "REAL_DIA_RECEIVED";
export const PROGRAMADO_SEMANA_RECEIVED = "PROGRAMADO_SEMANA_RECEIVED";
export const REAL_SEMANA_RECEIVED = "REAL_SEMANA_RECEIVED";
export const PROGRAMADO_MES_RECEIVED = "PROGRAMADO_MES_RECEIVED";
export const REAL_MES_RECEIVED = "REAL_MES_RECEIVED";
export const GET_ACTIVIDAD_PROGRAMADA = "GET_ACTIVIDAD_PROGRAMADA";
//User
export const LOGIN = "LOGIN";
export const LOGGEDIN = "LOGGEDDIN";
export const LOGOUT = "LOGOUT";
export const SET_DATOS = "SET_DATOS";
export const SET_NOMBRE_USUARIO = "SET_NOMBRE_USUARIO";
export const SET_FOTO_USUARIO = "SET_FOTO_USUARIO";
export const SHOW_SPINNER_USUARIO = "SHOW_SPINNER_USUARIO";
export const HIDE_SPINNER_USUARIO = "HIDE_SPINNER_USUARIO";
//Modal
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SET_RESPONSE = "SET_RESPONSE";
export const CLEAR = "CLEAR";
export const CLEAR_MODAL = "CLEAR_MODAL";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const CLEAR_ALERT = "CLEAR_ALERT";
export const MODAL_COMPONENT = "MODAL_COMPONENT";
export const SHOW_ALERT = "SHOW_ALERT";
export const SHOW_SUCCESS = "SHOW_SUCCESS";
//Spinner
export const SHOW_SPINNER = "SHOW_SPINNER";
export const HIDE_SPINNER = "HIDE_SPINNER";
//Retrasos
export const POST_RETRASO = "POST_RETRASO";
//Menu
export const SELECT_TABS = "SELECT_TABS";
export const SET_SELECTED = "SET_SELECTED";
export const TOGGLE_SETTINGS = "TOGGLE_SETTINGS";
//Alerts
export const ALERTAS_RECEIVED = "ALERTAS_RECEIVED";
export const TOGGLE_ALERT_CARD = "TOGGLE_ALERT_CARD";
export const CHECK_ALERTA = "CHECK_ALERTA";
export const SHOW_SPINNER_ALERTAS = "SHOW_SPINNER_ALERTAS";
export const HIDE_SPINNER_ALERTAS = "HIDE_SPINNER_ALERTAS";
export const CLEAR_ALERTS = "CLEAR_ALERTS";
//Tours
export const RECORRIDOS_RECIBIDOS = "RECORRIDOS_RECIBIDOS";
export const RECORRIDO_RECIBIDO = "RECORRIDO_RECIBIDO";
export const AGREGAR_RECORRIDO = "AGREGAR_RECORRIDO";
export const SET_PROPERTY_TOUR = "SET_PROPERTY_TOUR";
export const UPDATE_TOUR = "UPDATE_TOUR";
export const DELETE_TOUR = "DELETE_TOUR";
/**
 * Partidas
 */
export const PARTIDAS_PRECIARIO_RECIBIDO = "PARTIDAS_PRECIARIO_RECIBIDO";
export const CREATE_PARTIDA_PRECIARIO = "CREATE_PARTIDA_PRECIARIO";
export const EDIT_PARTIDAS_PRECIARIO = "EDIT_PARTIDAS_PRECIARIO";
export const SET_PROPERTY_PARTIDA_PRECIARIO = "SET_PROPERTY_PARTIDA_PRECIARIO";
export const SEARCH_PARTIDAS_PRECIARIO = "SEARCH_PARTIDAS_PRECIARIO";
export const POST_PRECIARIO_LOADING = "POST_PRECIARIO_LOADING";
export const DELETE_PARTIDA_PRECIARIO = "DELETE_PARTIDA_PRECIARIO";
export const REORDENAR_PARTIDAS = "REORDENAR_PARTIDAS";
/**
 * Conceptos
 */
export const CONCEPTOS_RECIBIDOS = "CONCEPTOS_RECIBIDOS";
export const EDIT_CONCEPTOS = "EDIT_CONCEPTOS";
export const SET_PROPERTY_CONCEPTO = "SET_PROPERTY_CONCEPTO";
export const DELETE_CONCEPTO = "DELETE_CONCEPTO";
export const CREATE_CONCEPTO = "CREATE_CONCEPTO";
/**
 * Estimaciones
 */
export const ESTIMACIONES_RECIBIDAS = "ESTIMACIONES_RECIBIDAS";
export const SELECT_ESTIMACION = "SELECT_ESTIMACION";
export const TOGGLE_ESTIMACIONES = "TOGGLE_ESTIMACIONES";
export const CANCEL_EDIT = "CANCEL_EDIT";
export const EDIT_ESIMATION = "EDIT_ESIMATION";
export const SET_PROPERTY_ESTIMACION = "SET_PROPERTY_ESTIMACION";
export const CREATE_ESTIMACION = "CREATE_ESTIMACION";
export const EDIT_CONCEPTOS_ESTIMACION = "EDIT_CONCEPTOS_ESTIMACION";
export const APPEND_ESTIMACION = "APPEND_ESTIMACION";
export const SAVE_ESTIMACION = "SAVE_ESTIMACION";
export const SAVE_CONCEPTOS_ESTIMACION = "SAVE_CONCEPTOS_ESTIMACION";
export const REESTABLECER_ESTIMACION = "REESTABLECER_ESTIMACION";
/**
 * Contratos
 */
export const CONTRATOS_RECIBIDOS = "CONTRATOS_RECIBIDOS";
export const SET_PROPERTY_CONTRATO = "SET_PROPERTY_CONTRATO";
export const SELECT_CONTRATO = "SELECT_CONTRATO";
export const CREATE_CONTRATO = "CREATE_CONTRATO";
export const EDIT_CONTRATO = "EDIT_CONTRATO";
export const SAVE_CONTRATO = "SAVE_CONTRATO";
export const CANCEL_EDIT_CONTRATO = "CANCEL_EDIT_CONTRATO";
export const SELECTING_CONTRATO = "SELECTING_CONTRATO";
/**
 * Montos
 */
export const MONTOS_RECIBIDOS = "MONTOS_RECIBIDOS";
/**
 * Pagos
 */
export const PAGOS_RECIBIDOS = "PAGOS_RECIBIDOS";
export const SET_PROPERTY_PAGO = "SET_PROPERTY_PAGO";
export const EDIT_PAGO = "EDIT_PAGO";
export const SAVE_PAGO = "SAVE_PAGO";
export const PAGO_TO_CANCEL = "PAGO_TO_CANCEL";
export const PAGOS_GENERALES_RECIBIDOS = "PAGOS_GENERALES_RECIBIDOS";
/**
 * Conceptos Extra
 */
export const CONCEPTOS_EXTRA_RECIBIDOS = "CONCEPTOS_EXTRA_RECIBIDOS";
export const EDIT_CONCEPTOS_EXTRA = "EDIT_CONCEPTOS_EXTRA";
export const SET_PROPERTY_CONCEPTO_EXTRA = "SET_PROPERTY_CONCEPTO_EXTRA";
export const CREATE_CONCEPTO_EXTRA = "CREATE_CONCEPTO_EXTRA";
export const SAVE_CONCEPTOS_EXTRA = "SAVE_CONCEPTOS_EXTRA";
export const DELETE_CONCEPTO_EXTRA = "DELETE_CONCEPTO_EXTRA";
/**
 * Loading
 */
export const LOADING_FINISHED = "LOADING_FINISHED";
/**
 * Archivos
 */
export const CARGAR_PRECIARIO = "CARGAR_PRECIARIO";
export const SET_PRECIARIO = "SET_PRECIARIO";
export const CLEAR_PRECIARIO = "CLEAR_PRECIARIO";
