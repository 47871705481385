import React from "react";
import FilaPago from "./FilaPago";

const TablaPagos = ({ pagos, permiso, editPago, cancelPago }) => {
  function renderPagos(pagos) {
    if (pagos && pagos !== null) {
      return pagos.map((pago, index) => (
        <FilaPago
          pago={pago}
          index={index}
          key={pago.idPago}
          permiso={permiso}
          editPago={editPago}
          cancelPago={cancelPago}
        />
      ));
    }
  }

  return (
    <div className="table-responsive th-border-0">
      <table className="table">
        <thead>
          <tr className="bg-light border bold">
            <th>#</th>
            <th>Concepto</th>
            <th>Monto</th>
            <th>Autorizado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>{renderPagos(pagos)}</tbody>
      </table>
    </div>
  );
};

export default TablaPagos;
